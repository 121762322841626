import {connectMetamask,connectWallet,web3signature,getNftInfo} from "../../utils/ethereum";
import {registerUser} from "./api"
  

export function userLogin(type,nft){
  return (dispatch)=>{
    let connect;
    if(type == 'wallet'){
      connect = connectWallet
    }else{
      connect = connectMetamask
    }
    dispatch({type:"LOGIN_REQUEST",providerType:type})

    connect(nft.chain_id,async(obj)=>{
      console.log("obj",obj);
      if(obj.type=='success'){
        const data = obj.data;
        let currentTime = new Date().getTime() ;
        // let message = "Please sign this message to login. - "+currentTime;
        let message = `Welcome to Festival Pass!\n\nSigning this message will not trigger a blockchain transaction, cost fees or allow us to transfer contents from your wallet.\n\nID: `+currentTime;

        web3signature(message,data.eth_address,async(webObj)=>{
          if(webObj.type=='success'){
            try{
              let apiData = await registerUser({eth_address:data.eth_address,time_stamp:currentTime,signature:webObj.data.signature});
              dispatch({type:"LOGIN_SUCCESS",data:{...apiData.data,time_stamp:currentTime},network:data.network,provider:(type == 'wallet') ? "wallet":"metamask"})
              console.log("nft,data.eth_address",nft,data.eth_address);
              let nftInfo = await getNftInfo(nft,data.eth_address);
              dispatch({type:"NFT_INFO",data:nftInfo});

            }catch(e){
              dispatch({type:"LOGIN_ERROR",message:e.message});
            }
          }else{
            
            dispatch({type:"LOGIN_ERROR",message:webObj.message});
          }
        })
       
      }else{
        dispatch({type:"LOGIN_ERROR",message:obj.message});
      }
      
    },dispatch)
  }
}

export function userReset(){
   return dispatch =>{  dispatch({type:"MY_NFTS_U_RESET"}); dispatch({type:"LOGIN_RESET"});}
}


export  function updateMintValue(nft,eth_address){
  return async dispatch =>{
    let nftInfo = await getNftInfo(nft,eth_address);
    dispatch({type:"NFT_INFO",data:nftInfo});
  }
}