import React from 'react'
import { connect } from 'react-redux'
import {Button,Modal} from 'react-bootstrap'

import TraitDesc from '../trait-desc';

import * as constants from "../../services/constants"


function ModalCard(props) {
  let {nft,webInfo}=props;
  let nftType = nft.type
  nft = nft.card
  console.log(nft);
  const trait = nft.attributes ? nft.attributes : [];

  const shareTwitter = ()=>{
    // window.open("https://twitter.com/share?text=I%27ve%20got%20my%20Festival%20Family%20Founder%27s%20Pass!%20Grab%20yours%20and%20never%20pay%20ticketing%20fees%20again! @getfestivalpass&url=&hashtags=festivalpass,nft");
    window.open(`http://www.twitter.com/share?text=I%20just%20minted%20my%20Festival%20Family%20Founder%27s%20Pass!%20Grab%20yours%20and%20never%20pay%20ticketing%20fees%20again!%20%20%40getfestivalpass&url=https://family.festivalpass.com/&hashtags=festivalpass,nft`);

  }

  const viewOnOpenSea=()=>{
    let url = process.env.REACT_APP_ETH_ENV =='production' ?  "https://opensea.io/assets/ethereum/" : "https://testnets.opensea.io/assets/rinkeby/";
     url +=webInfo.data.nft.contract_address+"/"+nft.id;
     window.open(url);

  }
  return (
    <>
    <Modal show={props.show} onHide={props.handleCloseAlert} backdrop={true} backdropClassName="modal-backdrop foo-modal-backdrop in" dialogClassName="modal-alert">
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
        <img src={nft.image}  alt={nft.name} className='w-100'/>

        <h2 className='text-center text-white mt-4 title'>{nft.name}</h2>

          <div className="row my-4">
            {trait.map((trait,index) => {
              return(
                <TraitDesc key={index} type={trait.trait_type} name={trait.value}/>
              )
            })}
          </div>
          {nftType =='allNfts' ?
           <button className="model-learn-more w-100" onClick={viewOnOpenSea}>View on OpenSea <i className="fa-solid fa-arrow-right"></i></button>
          :  
            <button className="model-learn-more w-100" onClick={shareTwitter}>Share on Twitter  <i className="fa-solid fa-arrow-right"></i></button>
            } 
         
        </Modal.Body>
    </Modal>
    </>
  )
}

const mapStateToProps = (state) => ({
  webInfo:state.webInfo,
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalCard)