import React from 'react'
import {Link} from 'react-router-dom'

function AboutLifetimeFounder() {
  return (
    <>
          <div className="row mt-5 mb-5 lifetime-founders">
            <div className="col-md-5">
              <img src="/assets/images/home.png" className='img-fluid' alt="Man" />
            </div>
          <div className="col-md-7">
              <h1 className='text-white title'>About the Festival Family - Founder's Pass</h1>
              <p className='mt-4 desc-lifetime-founder'>
                The festivalPass NFT provides unique perks and benefits including 1,080 credits (approximately $1,200 value) per year in perpetuity one can use to redeem live event tickets across 80,000 venues and hotel rooms.  
              </p>
              <p className='mt-2 desc-lifetime-founder'>
                Additional perks will include access to curated events only available to NFT holders. Such bucket list items include intimate concerts with top artists, VIP backstage access or luxury box access to sporting events.  
              </p>
              <div className='pt-4'>
                <button onClick={() => {window.open(`https://www.festivalpass.com/`)}} className="learn-more w-md-100">Learn More</button>
              </div>
          </div>
          </div>
    </>
  )
}

export default AboutLifetimeFounder