import { combineReducers } from 'redux';

import {webInfoReducer, creditsReducer,myNftsReducer,allNftsReducer,nftInfoReducer} from './list';
import {userReducer} from './user';

const rootReducer = combineReducers({
    user:userReducer,
    nftInfo:nftInfoReducer,
    webInfo:webInfoReducer,
    myNfts:myNftsReducer,
    allNfts:allNftsReducer,
    credits:creditsReducer
});

export default rootReducer;