export function  userReducer(state = {type:"",login:false,providerType:"",data:false,network:"",provider:"",message:""}, action){
  let obj;
  let tmp=[];
   obj ={...state};
  switch (action.type) {
    case "LOGIN_REQUEST":
      return {type:"request",login:false,providerType:action.providerType,data:false,network:"",provider:"",message:""}   
    case "LOGIN_RESET":
      return {type:"",login:false,providerType:obj.providerType,data:false,network:"",provider:"",message:""}
    case "LOGIN_SUCCESS":
      return {type:"success",login:true,providerType:obj.providerType,data:action.data,network:action.network,provider:action.provider}
    case "LOGIN_ERROR":
      return {type:"error",login:false,providerType:obj.providerType,data:false,network:"",provider:"",message:action.message}  
  }
  return state;
}
