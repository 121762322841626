import React from 'react'

function Credits(props) {
  const {credits} = props;
  return (
    <>
      <div className="row credits">
        <div className="col-lg-6">
          <h1 className='text-white title home-title'>Look Up Credits</h1>
          <p className='text-white mt-2 subtitle'>Use this tool to check the remaining credit balance and renewal data of any festivalPass NFT before purchasing on secondary markets.</p>

          <form onSubmit={props.onSubmit}>
            <div className='mt-4'>
              <input type="text" className='token-id' name='credits' placeholder='Enter a Token ID#' onChange={props.onChange} value={props.value} disabled={credits.type=='request'}/>
              <button className='theme-btn mt-lg-2 mt-3 ms-lg-3 ms-md-0 w-md-100' type='submit' disabled={credits.type=='request'}> {credits.type=='request' ? "Gathering info...":"View Credits"}</button>
            </div>
          </form>

          <div className='ms-3'>
   
            <p className='pt-2 mb-0' id="error">{props.errorMsg}</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Credits