import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
var moment = require("moment");

function EventCreditsRemaining(props) {
  const { credits, webInfo, tokenId } = props;
  const [nftObj, setNftObj] = useState(false);

  useEffect(() => {
    loadNftInfo();
  }, []);

  const loadNftInfo = async () => {
    let nftInfo = await fetch(
      `${process.env.REACT_APP_BASE_API_URL}/api/v1/nft/${tokenId}`
    );
    nftInfo = await nftInfo.json();

    setNftObj(nftInfo);
  };

  const viewOnOpenSea = () => {
    let url =
      process.env.REACT_APP_ETH_ENV == "production"
        ? "https://opensea.io/assets/ethereum/"
        : "https://testnets.opensea.io/assets/rinkeby/";
    url += webInfo.data.nft.contract_address + "/" + tokenId;
    return url;
  };
  return (
    <>
      <div className="row my-5">
        <div className="col-md-5 d-flex flex-column align-items-center">
          <img
            src={nftObj ? nftObj.image : "/assets/images/c-image.png"}
            alt={nftObj ? nftObj.name : "nft"}
            className="img-fluid rounded"
          />
          <div className="d-flex justify-content-center mt-4">
            <a href={viewOnOpenSea()} className="opensea" target="_blank">
              <img
                src="/assets/images/opensea.png"
                alt="opensea"
                className="me-2"
              />
              View on Opensea
            </a>
          </div>
        </div>
        <div className="col-md-7">
          <h1 className="text-white title">Event Credits Remaining</h1>
          <span className="remaining">{credits["events_credits"]}</span>
          <h4 className="total">of {credits["base_credits"]}</h4>
          <p className="light-text">
            (The credit amount available to the owner of this NFT right now)
          </p>

          <div className="mt-5">
            <h1 className="text-white title">Renews</h1>
            <span className="remaining">
              {moment(credits["renewal_date"], "X").format("M/DD/YY")}
            </span>
            <p className="light-text">
              (Credits will be reset to 1,080 on this date)
            </p>
          </div>

          <h1 className="text-white title mt-5">How the Credit System Works</h1>
          <p className="mt-3 text-white description">
            Every year a festivalPass NFT receives 1,080 credits to redeem
            towards over 80,000 live events on the date it was minted
            (approximately $1,200 in value). Holders have lifetime access to
            this utility as long as they keep their NFT.
          </p>
          <p className="text-white description">
            Holders can resell their NFT on the open market along with whatever
            balance it has left. Credit balances do not stack, and never exceed
            1,080.
          </p>

          <div className="pt-4">
            {/* <Link to="/mint" className='learn-more'>Learn More</Link> */}
            <button
              onClick={() => {
                window.open(`https://www.festivalpass.com/`);
              }}
              className="learn-more w-md-100"
            >
              Learn More
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  webInfo: state.webInfo,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventCreditsRemaining);
