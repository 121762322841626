import detectEthereumProvider from '@metamask/detect-provider';
import Web3 from 'web3';
import WalletConnectProvider from "@walletconnect/web3-provider";
import {ETH_ENV} from '../services/constants'
import chainObject from "../services/constants/chains.json"
import {ERC20} from "../services/abi/ERC20"
import {ERC721} from "../services/abi/ERC721"

import axios from "axios";
const ethereum = window.ethereum;
// for ethereum interaction function
export const web3 = new Web3();
window.web3Obj = web3;
var BigNumber = require('big-number');
const PerPage = 12;


export async function connectWallet(chain_id,callback,dispatch){
  let chainObj = {}
  for (let index = 0; index < chainObject.length; index++) {
    const element = chainObject[index];
    // if(chain_id == element.chainId){
      chainObj[element.chainId] = element.rpc[0];
    // }
  }

 
  let provider = new WalletConnectProvider({
    rpc: chainObj, // Required (from metamask mainnet)
  });
  
  // let provider = new WalletConnectProvider({
  //   infuraId: "af459aa169024c76a5485bc71b6e6617", // Required (from metamask mainnet)
  // });

  
  await provider.close().catch(async(error)=>{
    if(error){
      window.localStorage.removeItem('walletconnect'); 
      console.log("error",error);
    }
   
  })

  provider = new WalletConnectProvider({
    rpc: chainObj, // Required (from metamask mainnet)
  });
  let accounts = await provider.enable().catch((error)=>{
      dispatch({type:"LOGIN_RESET"});
  });
  if(accounts && parseInt(provider.chainId) != parseInt(chain_id)){
    return callback({type:"error",message: parseInt(chain_id) ==1 ? "Please change your network to Ethereum Mainnet." : "Please change your network to Ethereum Rinkeby."});
  }
  web3.setProvider(provider);  

  web3.eth.net.getNetworkType().then(async(network_type) => {
    let chain = chainObject.find(v=> v.chainId == provider.chainId);
    let network = {}
    network['type'] = chain.network;
    // network['chainId'] = chain.chainId;
    network['chainId'] = parseInt(chain_id);
    callback({type:"success",data:{network:network,eth_address:accounts[0]}});
  })

  provider.on("accountsChanged", (accounts) => {
    window.location.reload();
  });

  // Subscribe to chainId change
  provider.on("chainChanged", (chainId) => {
    let network = {}
    web3.eth.net.getNetworkType().then(async(network_type) => {
      let chain = chainObject.find(v=> v.chainId == chainId);
      network['type'] = chain.network;
      network['chainId'] = chain.chainId;
      network['networkVersion'] = "";
      dispatch({type:"NETWORK_CHANGE",network:network});
    });
  });
  // Subscribe to session disconnection
  provider.on("disconnect", (code, reason) => {
    window.location.reload();
  });
}

export  async function getTokenInfo(nft,tokenIds) {
  const nftContract = new web3.eth.Contract(ERC721, nft.contract_address);
  let tokenData = [];
  for(let i=0;i<tokenIds.length;i++){
    let url = await nftContract.methods.tokenURI(tokenIds[i]).call();
    let tokenInfo = await fetch(url).then(response => response.json());
    tokenData.push(tokenInfo);
  }
  return tokenData;
}

export async function connectMetamask(networkChainId,callback){
  if (typeof window.ethereum === 'undefined') {
    window.open("https://metamask.app.link/dapp/" +window.location.host);
    return
  } 
  ethereum.request({ method: 'eth_requestAccounts' }).then(accounts => {
    ethereum.on('accountsChanged', function (accounts) {
      window.location.reload();
    });

    if (ethereum.isMetaMask && ethereum.selectedAddress != null) { 
        detectEthereumProvider().then(provider=>{
          web3.setProvider(provider);
          web3.eth.net.getNetworkType().then(async(network_type) => {
            let network = {}
            network['type'] = network_type;
            network['chainId'] = await ethereum.request({ method: 'eth_chainId' });
            network['chainId'] = parseInt(network['chainId']);
            network['networkVersion'] = await ethereum.request({ method: 'net_version' });
            if(networkChainId != network['chainId']){
              return walletSwitchEthereumChain(networkChainId,'metamask',(data)=>{
                if(data.type=='success'){
                  connectMetamask(networkChainId,callback);
                }else{
                  callback(data);
                }
              });
            }else{
              ethereum.on('chainChanged', (chainId) => {
                console.log("chainChanged");
                window.location.reload();
              });
            }
            callback({type:"success",data:{network:network,eth_address:ethereum.selectedAddress}});
          })
        });
    }else{
      callback({type:"error",message: "Some error occurred!"});
    } 
  }).catch((error)=>{
    callback({type:"error",message: error.message});
  });
}

export function weiToEther(wei) {
 return  Web3.utils.fromWei(wei, 'ether');

}
export  async function mintWithEth(to,nft,signature) {
  to = web3.utils.toChecksumAddress(to);
  try{
    const nftContract = new web3.eth.Contract(ERC721, nft.contract_address);
    const amount = BigNumber(signature.price);
    const totalPrice = amount.mult(signature.quantity);
    console.log("to,signature",to,signature);
    let aTx = await nftContract.methods.mint(to,signature).send({from:signature.sender,value:totalPrice.toString()});
    console.log("aTx",aTx);

    let tokenIds =[];
    let returnValues = aTx.events['Minted'].returnValues;
    tokenIds = returnValues['tokenIds'];
    
    let finalTx;
    do {
      finalTx = await web3.eth.getTransactionReceipt(aTx.transactionHash);
    }while (!finalTx.status);
    
    return {type:"success",data:{finalTx,tokenIds,txHash:aTx.transactionHash},message:""}
  }catch(e){
    let msg = e.message;
    if(msg.includes('reverted')){
      msg ='Transaction has been reverted by the EVM.';
    }
    console.log("e",e);
    return {type:"error",data:{},message:msg};
  }
}

export async function web3signature(message,address,callback){
  try{
    var signature = await web3.eth.personal.sign(message, address);
    callback({type:"success",data:{signature}});
  }catch(error){
    callback({type:"error",message: error.message});
  }
}

export function getHaxChainId(id){
  return "0x"+(id).toString(16)
}

export async function walletAddEthereumChain(chainId,callback){
  chainId = parseInt(chainId);
  let chain = chainObject.find(v=> v.chainId == chainId);
  // wallet_addEthereumChain
  let object = { 
    chainId: getHaxChainId(chainId), // A 0x-prefixed hexadecimal string
    chain: "Matic Mainnet",
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18
    },
    rpcUrls: ["https://rpc-mainnet.maticvigil.com/"],
    blockExplorerUrls: ["https://polygonscan.com/"],
    iconUrls: ["https://polygon.technology/wp-content/uploads/2021/02/cropped-polygon-ico-32x32.png"]
  }

  if(!chain){
    callback({type:"error",message:"network chain - "+chainId+" not found!"});
    return 
  }else{
    object={ 
      chainId: getHaxChainId(chain.chainId), // A 0x-prefixed hexadecimal string
      chainName: chain.name,
      nativeCurrency: chain.nativeCurrency,
      rpcUrls: chain.rpc,
      blockExplorerUrls: (chain.explorers.length >0 ? chain.explorers.map((e)=>e.url) : null),
      iconUrls: ["https://polygon.technology/wp-content/uploads/2021/02/cropped-polygon-ico-32x32.png"]
    }
  }
  try {
    await ethereum.request({
      method: 'wallet_addEthereumChain',
      params: [object],
    });
    callback({type:"success",message:"wallet added to chain"});
  } catch (addError) {
    callback({type:"error",message:addError.message});
  }
}

export async function walletSwitchEthereumChain(chainID,provider,callback){
  if(provider == 'metamask'){
    try {
      await ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: getHaxChainId(parseInt(chainID)) }],
      });
      callback({type:"success",message:"network get switched to "+chainID});
    } catch (switchError) {
      if (switchError.code === 4902) {
        walletAddEthereumChain(chainID,(data)=>{
          if(data.type=='success'){
            walletSwitchEthereumChain(chainID,callback);
          }else{
            callback(data);
          }
        });
      }else{
        callback({type:"error",message:switchError.message});
      }
    }
  }
}

function addressEqual(to,from){
  return to.toString().toLowerCase() == from.toString().toLowerCase();
}


export async function nftInfo(eth_address){
  //  will return nft info 
  // total supply
  // max supply etc
}
 


// helper function 

export function shortEthAddress(address){
  let addr = web3.utils.toChecksumAddress(address);
  return addr.substr(0,6)+"..."+addr.substr(addr.length-4,4);
}

export function etherUrl(url) {
  if (ETH_ENV == 'production') {
    return "https://etherscan.io"+url;  
  } else {
    return "https://rinkeby.etherscan.io"+url; 
  }
}

export function openseaUrl(url) {
  if (ETH_ENV == 'production') {
    return "https://opensea.io"+url;  
  } else {
     return "https://testnets.opensea.io"+url; 
  }
}


export function getNetworkName(chainId){
 chainId = parseInt(chainId);
  let chain = chainObject.find(v=> v.chainId == chainId);

  if(!chain){
    chain={
      "name": "Private",
      "chainId": chainId,
      "shortName": "eth",
      "chain": "ETH",
      "network": "Private",
      "networkId": "",
      "nativeCurrency": {"name":"Ether","symbol":"ETH","decimals":18},
      "rpc": ["https://mainnet.infura.io/v3/${INFURA_API_KEY}","wss://mainnet.infura.io/ws/v3/${INFURA_API_KEY}","https://api.mycryptoapi.com/eth","https://cloudflare-eth.com"],
      "faucets": [],
      "explorers": [],
      "infoURL": "https://ethereum.org"
    }
  }
  return chain
}

export function explorerURL(chainId){
  chainId = parseInt(chainId);
  let chain = chainObject.find(v=> v.chainId == chainId);
  if(chain && chain.explorers.length > 0){
    return chain.explorers[0].url+"/";
  }else{
    return "#/";
  }
} 


export function validateAddress(address){
  try{
     address = web3.utils.toChecksumAddress(address);
    if(web3.utils.checkAddressChecksum(address)){
      return address;
    } 
  } catch (error) {
    console.log("error",error);
  }
    return "";
  
}

export function getSoliditySha3(data){
  if(Array.isArray(data)){
    return web3.utils.soliditySha3(...data)
  }else{
    return web3.utils.soliditySha3(data)
  }
}
export async function getWeb3Signature(message,eth_address){
   return await  web3.eth.personal.sign(message,eth_address);
}

export async function getRecoverAddress(message, signature){
  if(typeof message =='object'){
    return await web3.eth.accounts.recover(message);
  }else{
    return await web3.eth.accounts.recover(message, signature);
  }
  
}


export  async function loadMyNfts(nft,eth_address,from=0) {
  const nftContract = new web3.eth.Contract(ERC721, nft.contract_address);
  let  myNftsCount = await nftContract.methods.balanceOf(eth_address).call();
  let tokenIds =[];
  from = from*PerPage;
  let to = from+PerPage;
  to = to>myNftsCount ? myNftsCount : to;

  for(let i=from;i<to;i++){
    let tokenId = await nftContract.methods.tokenOfOwnerByIndex(eth_address,i).call();
    tokenIds.push(tokenId);
  }
  let nfts =  await getTokenInfo(nft,tokenIds);
  return  nfts
}

export  async function loadAllNfts(nft,from=0) {
  const nftContract = new web3.eth.Contract(ERC721, nft.contract_address);
  let  myNftsCount = await nftContract.methods.totalSupply().call();

  from = from*PerPage;
  
  let to = from+PerPage;
  to = to>myNftsCount ? myNftsCount : to;

  let tokenIds =[];

  console.log("from-to",from,to);
  for(let i=from;i<to;i++){
    let tokenId = await nftContract.methods.tokenByIndex(i).call();
    tokenIds.push(tokenId);
    // tokenIds.push(i+1);
  }
  let nfts =  await getTokenInfo(nft,tokenIds);
  return  nfts
}

export function getProviderUrl(chainId){
  let provider_url ='https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161';
  if(chainId =='4'){
    provider_url = 'https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161';
  }
  return provider_url 
}

export  async function getNftInfo(nft,eth_address) {
  if(web3.currentProvider == null){
    web3.setProvider(new web3.providers.HttpProvider(getProviderUrl(nft.chain_id)));
  }
  const nftContract = new web3.eth.Contract(ERC721, nft.contract_address);
  let  totalSupply = await nftContract.methods.totalSupply().call();
  let  myNftsCount = "";
  if(eth_address!=null){
    myNftsCount = await nftContract.methods.balanceOf(eth_address).call();
  }

  return {totalSupply,myNftsCount}
}
 