import React, { useState,useEffect,useRef } from 'react'
import { connect } from 'react-redux'
import { userLogin,userReset,mintNFT,webInfoAPI,updateMintValue,mintConfirm } from "../../services/actions"
import { useNavigate} from 'react-router-dom';
import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";

import {weiToEther} from "../../utils/ethereum";
var BigNumber = require('big-number');

Number.prototype.formatNumber = function(n, x) {
    var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
    return this.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,');
};
function Mint(props) {
  const {user,webInfo} = props;
  const navigate = useNavigate();

  const [cardMint, setCardMint] = useState(false);
  const [mintProcess, setMintProcess] = useState(false);
  const [cardProcess, setCardProcess] = useState(false);
  const [mintNumber, setMintNumber] = useState(1);
  const [ethPrice, setEthPrice] = useState(1500);
  const [error, setError] = useState("");

  const [signature, setSignature] = useState(false);

 
  let interval = null;
  let price_interval = null;
  const mintRef = useRef(null);
  let price = "";
  let nft_max_mint = 1;
  let total_minted = 0;
  let total_supply = 0;
  let image = webInfo.data.nft.base_image;
  let sale_type ="";
  if(webInfo.data.nft.sale.price){
    price = webInfo.data.nft.sale.price;
    nft_max_mint = webInfo.data.nft.sale.max_wallet;
    image = (webInfo.data.nft.sale.image_url == null ? webInfo.data.nft.base_image : webInfo.data.nft.sale.image_url);
    


    total_minted = webInfo.data.nft.sale.total_minted;
    total_supply = webInfo.data.nft.sale.total_supply;

    sale_type = webInfo.data.nft.sale.sale_type;
  }

  const setCardView=(flag)=>{
    setError("");
    setCardProcess(false);
    setMintProcess(false);
    setMintNumber(1);
    props.userReset();

      if(flag == true){
        setCardMint(true);mintWithCorsMint();
      }else{
        setCardMint(false);
      }
  }

  const getEthPrice = () => {
    fetch('https://api.coingecko.com/api/v3/coins/ethereum/contract/0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2').then(res => res.json()).then(res => {
      setEthPrice(res.market_data.current_price.usd);
    });
  }

  const login=(type)=>{
    if (type == "metamask") {
      props.userLogin('Metamask',webInfo.data.nft)
    } else {
      props.userLogin('wallet',webInfo.data.nft)
    }
  }

  useEffect(() => {
    props.webInfoAPI();
      interval = setInterval(() => {
        props.webInfoAPI();
      }, 60000);

       price_interval = setInterval(() => {
        getEthPrice();
      }, 600000);


      getEthPrice();

      return () => {
        clearInterval(interval);
        clearInterval(price_interval);
      }
   
  }, [])
  

  const setMintCount = (e) => {
    let num = e.target.value;
    if (num > 0) {
      setError("");
      setMintNumber(num);

    }
    const tokenCount = document.getElementById('token-count');
    if(num > nft_max_mint){
      tokenCount.style.background = '#fff3f3'
      tokenCount.style.border = '2px solid red'
    }else{
      tokenCount.style.background = '#fff'
      tokenCount.style.border = ''
    }
  }

  useEffect(()=>{
    if(cardMint){

      if(mintNumber > nft_max_mint && price!=""){
        setSignature(false);
        return
      }
      mintWithCorsMint();
    }
  },[mintNumber])


  const mintTokenBtn = ()=>{
    setError("");
    setMintProcess(true)
    let formData ={tranche_id:webInfo.data.nft.sale.tranche_id,quantity:mintNumber,sender:user.data.eth_address,receiver:user.data.eth_address}
    // let formData ={tranche_id:webInfo.data.nft.sale.tranche_id,quantity:mintNumber,sender:"0xdAb1a1854214684acE522439684a145E62505233",receiver:"0xF62b9C46e0E1C071c03C8A1dE4Bf4a6D5e49f971"}
      props.mintNFT(webInfo.data.nft,formData,(type,data)=>{
        setMintProcess(false)
          if(type =="success"){
            props.updateMintValue(webInfo.data.nft,user.data.eth_address);
            console.log("data-xx",data);
            props.mintConfirm(data.data.txHash);
            setTimeout(()=>{
              props.webInfoAPI();
            },1000);
            navigate('/success',{state:{pathname:'/success',mintData:data}})
          }else{
            setError(data.message)
          }
         
      })
  }

  const mintWithCorsMint = ()=>{
    setSignature(false);
    setError("");
    setMintProcess(true)
    let formData ={tranche_id:webInfo.data.nft.sale.tranche_id,quantity:mintNumber,sender:process.env.REACT_APP_CROSSMINT_ADDRESS}
      props.mintNFT(webInfo.data.nft,formData,(type,data)=>{
        if(type=="success"){
          let signatureObj = data.data;
          const amount = BigNumber(signatureObj.price);
          let totalPrice = amount.mult(signatureObj.quantity);
          let crossMintSing ={
            totalPrice:weiToEther(totalPrice.toString()),
            mintData:signatureObj,
          }
          totalPrice= weiToEther(totalPrice.toString());
          setSignature(crossMintSing)
          setMintProcess(false);
        }else{
          setError(data.message)
        }
      })
  }

  const setNumber = (num)=>{
    return  Number((num).toFixed(1)).toLocaleString()
  }

  let buyWithCard = (sale_type!='allowlist')  ?  <button className="learn-more w-md-100" onClick={()=>{setCardView(true);}}>Buy with Credit Card</button> : null;

  return (
    <div className='container mint mt-5'>
      <div className="row">
        <div className="col-lg-5 d-flex justify-content-center flex-column">
          <img src={image} alt={webInfo.data.nft.name} className="img-fluid" width={"90%"} />
          {(cardMint && !cardProcess) &&
          <div className='text-white mt-4 ms-2' style={{width:"90%"}}>
          FestivalPass partnered with <u><a href="https://www.crossmint.io/" target={"_blank"} className="text-white">Crossmint</a></u> to make purchasing a Festival Family’ Founder’s Pass NFT as easy as possible (no crypto required.)
          </div>
          }
        </div>
        <div className="col-lg-7">
          {(total_minted > 0) &&
            <span className='remaining text-white'>{total_minted} of {total_supply.formatNumber()} Minted</span>
          }
          <h1 className="title text-white my-3">{cardProcess ? "Buying with Crossmint?" : (cardMint? "Buy a Founder's Pass NFT with a Credit Card" : webInfo.data.settings.home_title)}</h1>

          {cardProcess ?
            <div className="desc text-white">
                Here are some resources to help with your purchase and  instructions on how to use your NFT's credits to attend events. 
                <br/>
                <br/>
                <ul>
                  <li>How to redeem credits to attend events</li>
                  <li>Having trouble purchasing with Crossmint?  Contact us on Twitter or Discord</li>
                </ul>
            </div>
            :
            <p className="desc text-white">{webInfo.data.settings.home_details} </p>
          }
         

          <div className="card convertion-card mt-4">
            <div className="card-body p-0">
            {user.login ?
             <>
             {price!="" ?
              <>
              {(sale_type=='allowlist')&&
              <>
              <h3 className='text-white allow_list_title'>Allow List Sale</h3>
              <p className='text-white allow_list_p mb-4'>(Only members of the list can mint)</p>
              </>
              }
              <h1 className="card-title"><img src="/assets/images/eth.png" alt="ETH" className='me-3'/>Price: {price} ETH</h1>
              <div className='input-amount-area d-flex align-items-center mt-4'>
              <div className='input-group w-60'>
              <span className="input-group-text">QTY:</span>
                <input className={mintNumber > nft_max_mint ? "token-id err_box" : "token-id"} type="number" value={mintNumber} placeholder={`Number of ${webInfo.data.nft.nft_symbol} (max ${nft_max_mint})`} id="token-count" min="1" max={nft_max_mint} onChange={(e) => setMintCount(e)} disabled={mintProcess} />
              </div>
                <button className={mintProcess ? 'theme-btn token-id-btn ms-lg-2 ms-0 opacity-50' : 'theme-btn token-id-btn ms-lg-2 ms-0'} onClick={()=>{mintTokenBtn()}} disabled={mintNumber > nft_max_mint || mintProcess}>{mintProcess ? "Minting..." : <>Mint Founder's Pass</>}</button>
              </div>
               </>
               :
               <>
                <div className=''>
                  <h1 className="card-title" style={{color:"#F7276B"}}>Minting Paused</h1>
                  <p className='text-white' style={{fontSize: '1.5rem'}}>To learn more, visit us on social</p>
                  <div>
                    <a href={webInfo.data.settings.social_twitter} rel="noreferrer" target="_blank"><i className="fa-brands fa-twitter fa-3x text-white"></i></a>
                    <a href={webInfo.data.settings.social_discord} rel="noreferrer" target="_blank"><i className="fa-brands fa-discord ms-3 fa-3x text-white"></i></a>
                  </div>
                </div>
               </>
              }
              </>
              :
              <>
                {cardMint == false &&
                <div className='text-center'>
                  <div className='d-lg-flex d-sm-inline '>
                    <button className='btn btn-sm my-3 wallet-connect-btn wallet-button me-lg-3 ms-0' onClick={() => { login('metamask'); }}><img src="/assets/images/metamask-fox-logo.svg" alt="metamask fox" /> MetaMask Connect</button>
                    <button className='btn btn-sm my-3 wallet-connect-btn  wallet-button ms-lg-3 ms-0' onClick={() => { login('wallet'); }}><img src="/assets/images/walletconnect-logo.png" width="35" alt="wallet connect" /> Wallet Connect</button>
                  </div>
                  <br/>
                  <div className='d-lg-flex d-sm-inline '>
                  {buyWithCard}
                  </div>
                </div>
                }
              </>
            }

            {(cardMint)  &&
               <>
               {price!="" ?
                <>
                {cardProcess ? 
                  <>
                <br/>
                <div className='text-right'>
              
                <div onClick={()=>{setCardView(true);}} style={{color:"#F7276B",textDecoration:"underline",cursor:"pointer"}}>Purchase with Credit Card</div> 
                <div className='mt-3' onClick={()=>{setCardView(false);}} style={{color:"#F7276B",textDecoration:"underline",cursor:"pointer"}}>Purchase with ETH</div>
                </div>
                <br/>
                <br/>
                  </>
                  :
                
                <>
                <h1 className="card-title"> Price: ${setNumber(parseInt(parseFloat(price)*ethPrice))} USD <span style={{fontWeight:"normal"}}>(+ Fees)</span></h1>
                <div className='mt-4'>
                  <div className='buy-with-card d-flex'>
                  <div className='input-group'>
                  <span className="input-group-text">QTY:</span>
                    <input className={mintNumber > nft_max_mint ? "token-id err_box" : "token-id"} type="number" value={mintNumber} placeholder={`Number of ${webInfo.data.nft.nft_symbol} (max ${nft_max_mint})`} id="token-count" min="1" max={nft_max_mint} onChange={(e) => setMintCount(e)} disabled={mintProcess} />
                  </div>

                {(signature) &&
                 <CrossmintPayButton
                 className='ms-md-3'
                 style={{display:"inline"}}
                 collectionTitle={webInfo.data.nft.name}
                 collectionDescription={webInfo.data.nft.details}
                 collectionPhoto={image}
                 clientId={process.env.REACT_APP_CROSSMINT_CLIENT_ID}
                 environment={process.env.REACT_APP_CROSSMINT_ENV}
                 mintConfig={signature}
                 onClick={()=>{setCardProcess(true);}}
               /> 
                }
                </div>
                <br/>
                <p className='crossmint_note'>
                Note: The sale has a limit of 5 NFTs per wallet. Using a Credit Card will allow you to mint up to 3 NFTs in a single Transaction. If you would like to mint the max of 5 please execute two transactions for 3 and 2 NFTs respectively.
                </p>
                <div className='text-right'>
                <span onClick={()=>{window.open("https://family.festivalpass.com/how-to-use-the-nft");}} style={{color:"#F7276B",textDecoration:"underline",cursor:"pointer"}}>I've purchased with Crossmint, how do I use my credits?</span> <br/> <br/>
                <span onClick={()=>{setCardView(false);}} style={{color:"#F7276B",textDecoration:"underline",cursor:"pointer"}}>Purchase with ETH</span>
                </div>
                <br/>
                <br/>
                </div>
                  </>
                  }
                </>
                  :
                  <>
                    <div className=''>
                      <h1 className="card-title" style={{color:"#F7276B"}}>Minting Paused</h1>
                      <p className='text-white' style={{fontSize: '1.5rem'}}>To learn more, visit us on social</p>
                      <div>
                        <a href={webInfo.data.settings.social_twitter} rel="noreferrer" target="_blank"><i className="fa-brands fa-twitter fa-3x text-white"></i></a>
                        <a href={webInfo.data.settings.social_discord} rel="noreferrer" target="_blank"><i className="fa-brands fa-discord ms-3 fa-3x text-white"></i></a>
                      </div>
                    </div>
                  </>
                } 
               </>
            }



              {(mintNumber > nft_max_mint && price!="") &&
                <p id="error_msg" name='pt-2 '>You can mint a maximum of {nft_max_mint} {webInfo.data.nft.nft_symbol} at a time.</p>
              }

              {(error != ""  && price!="") &&
                <p id="error_msg" className='pt-2 '>{error}</p>
              }
              {user.message != "" &&
                <p id="error_msg" className='pt-2 text-center'>{user.message}</p>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


const mapStateToProps = (state) => ({
  user: state.user,
  webInfo:state.webInfo,
})

const mapDispatchToProps = {
  userLogin,
  userReset,
  mintNFT,
  webInfoAPI,
  updateMintValue,
  mintConfirm
}

export default connect(mapStateToProps, mapDispatchToProps)(Mint)
