import axios from "axios";
import {
  mintWithEth,
  loadMyNfts,
  loadAllNfts,
  getNftInfo,
} from "../../utils/ethereum";
axios.defaults.baseURL = process.env.REACT_APP_BASE_API_URL;

export async function registerUser(data) {
  let user_obj = await axios.post("/api/v1/login", data);
  axios.defaults.headers = {
    ...axios.defaults.headers,
    time_stamp: data.time_stamp,
    signature: data.signature,
  };
  // axios.headers={...axios.defaults.headers,'time_stamp':data.time_stamp,'signature':data.signature};
  return user_obj.data;
}

export function webInfoAPI(request_call = false) {
  return async (dispatch) => {
    if (request_call) {
      dispatch({
        type: "WEB_INFO_REQUEST",
      });
    }

    let webInfo;
    try {
      webInfo = await axios.get("/api/v1/web_info");
    } catch (e) {
      webInfo = { data: false };
    }

    if (webInfo.data) {
      dispatch({
        type: "WEB_INFO_SUCCESS",
        data: webInfo.data,
      });

      let nftInfo = await getNftInfo(webInfo.data.nft, null);
      dispatch({ type: "NFT_INFO", data: nftInfo });
    }
  };
}

export function mintNFT(nft, data, callback) {
  return async (dispatch) => {
    try {
      let object = await axios.post("/api/v1/generate_signature", data);
      if (object.data.type == "success") {
        let res;
        if (data.receiver) {
          res = await mintWithEth(data.receiver, nft, object.data.data);
        } else {
          return callback("success", object.data);
        }
        if (res.type == "success") {
          callback("success", res);
        } else {
          callback("error", { message: res.message, code: 0 });
        }
      } else {
        callback("error", {
          message: object.data.message,
          code: object.data.code,
        });
      }
    } catch (e) {
      callback("error", { message: e.message, code: 0 });
    }
  };
}

export function mintConfirm(txHash, callback) {
  return async (dispatch) => {
    let object = await axios.get("/api/v1/confirm_tx/" + txHash);
    callback(object.data);
  };
}

export function viewCreditsApi(data) {
  return async (dispatch) => {
    dispatch({
      type: "VIEW_CREDITS_REQUEST",
    });
    try {
      // let object = await axios.post(process.env.REACT_APP_CREDIT_URL,data);

      const rawResponse = await fetch(process.env.REACT_APP_CREDIT_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-fp-access": process.env.REACT_APP_CREDIT_API_KEY,
        },
        body: JSON.stringify(data),
      });
      let object = await rawResponse.json();
      console.log("object", object);
      if (object.success) {
        console.log("success", true);
        dispatch({
          type: "VIEW_CREDITS_SUCCESS",
          data: object,
        });
      } else {
        console.log("error", object.message);
        dispatch({
          type: "VIEW_CREDITS_ERROR",
          data: {},
          message: object.message,
        });
      }
    } catch (e) {
      dispatch({
        type: "VIEW_CREDITS_ERROR",
        data: {},
        message: "Token ID not found.",
      });
    }
  };
}

export function loadMyNftsData(nft, eth_address, from = 0) {
  return async (dispatch) => {
    if (from == 0) {
      dispatch({ type: "MY_NFTS_RESET" });
    } else {
      dispatch({ type: "MY_NFTS_PENDING" });
    }
    let myNfts = await loadMyNfts(nft, eth_address, from);
    dispatch({ type: "MY_NFTS_SUCCESS", data: myNfts });
  };
}

export function loadAllNftsData(nft, from = 0) {
  return async (dispatch) => {
    if (from == 0) {
      dispatch({ type: "ALL_NFTS_RESET" });
    } else {
      dispatch({ type: "ALL_NFTS_PENDING" });
    }
    let allNfts = await loadAllNfts(nft, from);
    dispatch({ type: "ALL_NFTS_SUCCESS", data: allNfts });
  };
}
