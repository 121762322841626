import React, {useState} from 'react'
import { connect } from 'react-redux'
import { Container,Navbar,Nav ,NavDropdown} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import site_logo from '../../logo-whiteText.svg';

import { shortEthAddress} from "../../utils/ethereum"
import { userReset} from "../../services/actions"

function Header(props) {
  const [expanded, setExpanded] = useState(false);
  const {user,webInfo} = props;

  return (
    <>
      <Navbar expand="lg" variant="dark" expanded={expanded} fixed="top">
        <Container>
          
          <Link to="/">
            {/* <img src={webInfo.data.settings.logoUrl} alt="Festival Pass" /> */}
            <img src={site_logo} alt="Festival Pass" height="50" />
          </Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={()=>setExpanded(!expanded)} />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <a href="https://family.festivalpass.com/" target={"_blank"} className="nav-link" onClick={()=>setExpanded(false)}>About</a>
              <Link to="/credits" className="nav-link" onClick={()=>setExpanded(false)}>Credits</Link>
              <Link to="/gallery" className="nav-link" onClick={()=>setExpanded(false)}>Gallery</Link>
              <Link to="/mint" className="nav-link" onClick={()=>setExpanded(false)}>Mint</Link>
              <NavDropdown title={"Support"} id="basic-nav-dropdown">
                <NavDropdown.Item onClick={()=>{window.open(webInfo.data.settings.social_discord)}} >Discord</NavDropdown.Item>
                <NavDropdown.Item onClick={()=>{window.open(webInfo.data.settings.social_twitter)}} >Twitter</NavDropdown.Item>
                <NavDropdown.Item onClick={()=>{window.open("https://family.festivalpass.com/how-to-use-the-nft")}} >How To Use</NavDropdown.Item>
              </NavDropdown>
              {user.login &&
              <NavDropdown title={
                <div className='d-flex align-items-center'>
                <span className='nav-link dropdown-toggle'>{shortEthAddress(user.data.eth_address)}</span>
                <img src='/assets/images/profile.svg' alt='profile' className='img-fluid ms-2'/>
                </div>
              } id="basic-nav-dropdown">
                <NavDropdown.Item  onClick={()=>{props.userReset();}}>Log Out</NavDropdown.Item>
              </NavDropdown>
            }
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}


const mapStateToProps = (state) => ({
  user: state.user,
  webInfo:state.webInfo,
})

const mapDispatchToProps = {
  userReset
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
