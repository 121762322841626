import React,{useState,useEffect} from 'react'
import { Link } from 'react-router-dom'
import { useNavigate} from 'react-router-dom';


import { useLocation} from 'react-router-dom';
import { connect } from 'react-redux'

import {getTokenInfo} from "../../utils/ethereum";



function SuccessScreen(props) {
    const {user,webInfo} = props;
    const { state } = useLocation();
    const navigate = useNavigate();
    const [tokenInfo,setTokenInfo]=useState([]);
    const [showToken,setShowToken]=useState(false);

    let mintData = null;
    if(!state){
        mintData={tokenIds:[]};
    }else{
        mintData = state.mintData.data;
    }

    useEffect(()=>{  
        if(!user.login){
            return  navigate('/mint');
        }
       let a = async () => {
        if(mintData && mintData.tokenIds.length>0){
            let tokenData = await getTokenInfo(webInfo.data.nft,mintData.tokenIds);
            console.log("tokenData-xx",tokenData);
            setTokenInfo(tokenData);
            if(tokenData.length > 0){
                setShowToken(0)
            }
        }else{
            navigate('/mint');
        }
       }
       a();
    },[]);


    const shareTwitter = ()=>{
        window.open(`http://www.twitter.com/share?text=I%20just%20minted%20my%20Festival%20Family%20Founder%27s%20Pass!%20Grab%20yours%20and%20never%20pay%20ticketing%20fees%20again!%20%20%40getfestivalpass&url=https://family.festivalpass.com/&hashtags=festivalpass,nft`);
      }

  return (
    <>{state&& 
        <div className="container success-screen py-5">
            <div className="row justify-content-center success">
                <div className="col-lg-5">
                   <div className="row justify-content-center pt-5 success-bg">
                       <div className="col-lg-7">

                       {showToken===false ?
                       
                       <div className="card text-center">
                            <img src="/assets/images/no-image.svg" className=" card-img-top" alt="..."/>
                            <div className="card-body">
                                <h5 className="card-title">Loading...</h5>
                                <a href="#"></a>
                            </div>
                        </div>
                        :
                        <div className="card text-center">
                                <img src={tokenInfo[showToken].image} className=" card-img-top" alt="..." key={showToken}/>
                                <div className="card-body">
                                    <h5 className="card-title title">{tokenInfo[showToken].name} </h5>
                                    <Link to="/gallery" className="green_text text-start">View In Gallery</Link>
                                </div>
                            </div>
                        }

                        {mintData.tokenIds.length>1 &&
                            <div className='toggle-arrow mt-4 text-center'>
                            <button className="btn btn-link" disabled={showToken==0} onClick={()=>{setShowToken(showToken-1)}}>     <i className="fa-solid text-white fa-arrow-left"></i>
                            </button>
                            <button className="btn btn-link" disabled={showToken==(tokenInfo.length-1)} onClick={()=>{setShowToken(showToken+1)}}>
                                <i className="fa-solid text-white fa-arrow-right ms-4"></i>
                            </button>
                            </div>
                     }
                       </div>
                   </div>
                </div>
                <div className="col-lg-5 pt-4 success-text">
                    <h1 className='title'>You did it! Welcome to the Festival Family.</h1>
                    <p className="pt-2">Congrats! You've got your Founder's Pass. Check it out on our gallery page and let your followers know that you're part of the Fam! Head to <a href="https://www.festivalpass.com/" className="text-white text-decoration-underline" rel="noreferrer" target="_blank">festivalpass.com</a> and create an account to gain access to your credits!</p>
                    <div className="text-center">
                        <div className='d-lg-flex d-sm-inline justify-content-center align-items-center'>
                            <Link to="/mint" className="learn-more my-3">Mint Another</Link>
                            <button className="learn-more my-4 ms-lg-3 ms-md-0" onClick={shareTwitter}>Share on Twitter <i className="fa-solid fa-arrow-right"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        }
    </>
  )
}


const mapStateToProps = (state) => ({
    user: state.user,
    webInfo:state.webInfo,
  })
  
  const mapDispatchToProps = {
  }
  
export default connect(mapStateToProps, mapDispatchToProps)(SuccessScreen);