import logo from './logo.svg';
import './styles/style.css';
import RoutesObj from './routes';
import { Provider } from "react-redux";
import configureStore from "./services/configureStore";
import { stopReportingRuntimeErrors } from "react-error-overlay";
const store = configureStore();
// import ReactGA from "react-ga";

// if (process.env.REACT_APP_ETH_ENV =="production" ) {
//   stopReportingRuntimeErrors(); // disables error overlays
// }
if (process.env.REACT_APP_ETH_ENV =="production" && window.location.protocol !== 'https:') {
  window.location.replace(`https:${window.location.href.substring(window.location.protocol.length)}`);
}

function App() {
  return (
    <Provider store={store}>
      <RoutesObj/>
    </Provider>
  );
}

export default App;
