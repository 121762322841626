import React,{useEffect} from "react";
import {Routes,Route,BrowserRouter, Navigate} from "react-router-dom";
import { useLocation} from 'react-router-dom'
import { connect } from 'react-redux'

import {LayoutOne} from "../layouts"
import Gallery from "../pages/gallery";
import Homepage from "../pages/home";
import Mint from "../pages/mint";
import SuccessScreen from "../pages/success";

import {webInfoAPI} from "../services/actions"



function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
      window.scrollTo(0, 0);
  }, [pathname]);
  return props.children
}
const ScrollToTop =(_ScrollToTop)

function RouteWrapper({ component: Component, layout: Layout, ...rest }) {
  return (
    <Layout {...rest} page={rest.page}>
      <Component {...rest} />
    </Layout>
  );
}

const RoutesObj = (props) => {
  useEffect(() => {
    props.webInfoAPI(true);
  },[]);
  return (
    <>
    <BrowserRouter>
      <Routes>
        {props.webInfo.type =='success'&&
        <>
          <Route exact path="/credits" element={<RouteWrapper exact component={Homepage} layout={LayoutOne}/> } />
          <Route exact path="/mint" element={<RouteWrapper exact component={Mint} layout={LayoutOne}/> } />
          <Route exact path="/gallery" element={<RouteWrapper exact component={Gallery} layout={LayoutOne}/> } />
          <Route exact path="/success" element={<RouteWrapper exact component={SuccessScreen} layout={LayoutOne}/> } />
          <Route path="/" element={<Navigate to="/mint" />} />
        </>
        }
      </Routes>
    </BrowserRouter>
    </>
  )
}


const mapStateToProps = (state) => ({
  user: state.user,
  webInfo:state.webInfo,
})

const mapDispatchToProps = {
  webInfoAPI
}

export default connect(mapStateToProps, mapDispatchToProps)(RoutesObj)
