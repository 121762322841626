import React from 'react'
import Header from '../components/header'

export const LayoutOne=(props)=>{
  return (
    <>
    <Header/>
      <section className='mb-5 pt-5rem'>
        {props.children}
      </section>
    </>
  )
}