import React,{useEffect, useState} from 'react';
import {connect} from 'react-redux'
import Credits from '../../components/credits'
import {Link} from 'react-router-dom'
import AboutLifetimeFounder from '../../components/about-lifetime-founder'
import EventCreditsRemaining from '../../components/event-credits-remaining'
import {viewCreditsApi} from '../../services/actions'

function Homepage(props) {
  const {credits,webInfo} = props;
  const [tokenId, setTokenId] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const [component, setComponent] = useState(false)
  const [creditsRemaining, setCreditsRemaining] = useState('')

  const tokenIdHandler = (e) => {
    setTokenId(e.target.value)
  }
  
  const submitHandler = (e) => {
    e.preventDefault();
    let data={
      contract_address:webInfo.data.nft.contract_address,
      token_id:tokenId,
      plan_tier:"founder"
    }
    setErrorMsg('');
    setComponent(false)
    props.viewCreditsApi(data);
  }

  useEffect(() => {
    setCreditsRemaining(tokenId)
    if(credits.type == 'success'){
      // setTokenId('')
      setErrorMsg('')
      setComponent(true)
    }else if(credits.type == 'error'){
      setTokenId('')
      setErrorMsg('Token ID Not Found')
      setComponent(false)
    } else {
      setComponent(false)
    }
  } , [credits]);
  return (
    <>
      <div className="container homepage mt-5">
        <Credits onChange={tokenIdHandler} value={tokenId} onSubmit={submitHandler} errorMsg={errorMsg} credits={credits}/>
        {!component ? 
        <AboutLifetimeFounder/>
        :
        <EventCreditsRemaining tokenId={creditsRemaining} credits={credits.data} />}
      </div>
    </>
  )
}


const mapStateToProps = (state) => ({
  credits: state.credits,
  webInfo:state.webInfo,
})

const mapDispatchToProps = {
  viewCreditsApi
}

export default connect(mapStateToProps, mapDispatchToProps)(Homepage)