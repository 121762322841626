import React from 'react'

function TraitDesc(props) {
  return (
    <div className="col-md-4 mb-3 d-flex flex-column justify-content-center">
      <div className="desc-card">
        <h6 className="text-center">{props.type}</h6>
        <h4 className='text-center'>{props.name}</h4>
      </div>
    </div>
  )
}

export default TraitDesc