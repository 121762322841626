import React,{useState,useEffect} from 'react'
import { connect } from 'react-redux'
import {Link} from 'react-router-dom'

import {Tabs,Tab} from 'react-bootstrap'
import NftCard from '../../components/nft-card'

import {loadMyNftsData,loadAllNftsData,userLogin,updateMintValue} from "../../services/actions"
import ModalCard from '../../components/nft-card/modal';


function Gallery(props) {
  const {user,webInfo,nftInfo,myNfts,allNfts} = props;
  const [showNftCard, setShowNftCard] = useState(false);
  const [currentTab, setCurrentTab] = useState('my_nft');
  const [myFrom, setMyFrom] = useState(0);
  const [allFrom, setAllFrom] = useState(0);

  useEffect(()=>{
    loadNfts(webInfo.data.nft,currentTab);
    if(user.login){
      props.updateMintValue(webInfo.data.nft,user.data.eth_address);
    }
  },[user.login]);

  const changeTab=(val)=>{
    setCurrentTab(val);
    loadNfts(webInfo.data.nft,val);
  }

  const loadNfts =(nft,tab_name)=>{
    if(tab_name == 'my_nft'){
      if(user.login && (myNfts.type=="" || myNfts.type=="success")){
        props.loadMyNftsData(nft,user.data.eth_address,myFrom);
        setMyFrom(myFrom+1)
      }
    }else{
      if(allNfts.type=="" || allNfts.type=="success"){
        props.loadAllNftsData(nft,allFrom);
        setAllFrom(allFrom+1);
      }
    }
  }

  return (
    <>
     {showNftCard ?
        <>
           <ModalCard show={showNftCard!=false} nft={showNftCard} handleCloseAlert={() => { setShowNftCard(false); }} />
        </>
        : ""}
      <section className="gallery">
        <div className="container py-5">
          <div className="row py-5">
            <div className="col-lg-5 pt-3">
              <h3 className='heading text-white'>10,000 Festival Family - Founder's Pass NFTs </h3>
              <h1 className='title text-white'>{webInfo.data.settings.gallery_title}</h1>
              <p className='sub-heading text-white'>{webInfo.data.settings.gallery_details}</p>
            </div>
            <div className="col-lg-7">
              <div className="row">
                <div className="col-md-6 text-center">
                  <img src={webInfo.data.settings.gallery_image_1} alt="NFT" className='img-fluid mb-2'/>
                </div>
                <div className="col-md-6 text-center">
                  <img src={webInfo.data.settings.gallery_image_2} alt="NFT" className='img-fluid mb-2'/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container tabs">
      <Tabs defaultActiveKey="my_nft" id="uncontrolled-tab-example" className="mb-3" activeKey={currentTab} onSelect={changeTab}>
            <Tab eventKey="all_nfts" title={`All NFTs (${nftInfo.totalSupply})`}>
          <div className="row">
          {(allNfts.type=='success' && allNfts.data.length == 0)&&
             <div className='d-flex justify-content-center align-items-center my-5'>
                <h2 className='text-center text-white'>No NFTs? No worries!</h2>
                <Link to="/mint" className="theme-btn ms-3">Mint Now</Link>
              </div>
            }

            {allNfts.type=='new_request'?
              <div className="d-flex justify-content-center align-self-center">
                <h3  className="pt-5 text-white"> Loading...</h3>
                <br/>
                <br/>
                </div>
                :
                <>
                {allNfts.data.map((nft,index) => <NftCard type="allNfts" key={index} nft={nft} viewNft={(v,t)=>setShowNftCard({card:v,type:t})}/>)}
                </>
              }

              {((allNfts.type=='success' || allNfts.type=='request') && allNfts.data.length < nftInfo.totalSupply) &&
                <div className="d-flex justify-content-center align-self-center pt-4">
                  <button onClick={()=>loadNfts(webInfo.data.nft,currentTab)} className="load-more-btn px-5 mint_btn align-items-center" disabled={allNfts.type=='request'}> {allNfts.type=='request' ? "Loading..." : "Load More"}</button>
                </div>
              }
          </div>
        </Tab>
        <Tab eventKey="my_nft" title={`Your NFTs${nftInfo.myNftsCount > 0 ? ` (${nftInfo.myNftsCount })` :"" }`}>
        <div className="row">
            {(myNfts.type=='success' && myNfts.data.length == 0)&&
                <div className='d-flex justify-content-center align-items-center my-5'>
                  <h2 className='text-center text-white'>No NFTs? No worries!</h2>
                  <Link to="/mint" className="theme-btn ms-3">Mint Now</Link>
                </div>
               }

          {!user.login &&
          <>
            <div className="row justify-content-center">
              <div className='col-md-7'>
                <div className='d-lg-flex d-sm-inline justify-content-center align-items-center'>
                  <button className='theme-btn wallet-connect-btn my-3 wallet-button me-lg-3' onClick={()=>{props.userLogin('Metamask',webInfo.data.nft)}}><img src="/assets/images/metamask-fox-logo.svg" alt="metamask fox" /> MetaMask Connect</button>
                  <button className='theme-btn wallet-connect-btn my-3 ms-lg-3 ms-md-0 wallet-button me-lg-3' onClick={()=>{props.userLogin('wallet',webInfo.data.nft)}}><img src="/assets/images/walletconnect-logo.png" width="35" alt="wallet connect" /> Wallet Connect</button>
                </div>
              </div>
            </div>
            {user.message != "" &&
              <div className='d-flex justify-content-center'> <p id="error_msg" className='pt-2 text-center'>{user.message}</p> </div>
            }
          </>
          }

          {myNfts.type=='new_request'?
            <div className="d-flex justify-content-center align-self-center">
            <h3  className="pt-5 text-white"> Loading...</h3>
            <br/>
            <br/>
          </div>
          :
          <>
            {myNfts.data.map((nft,index) => <NftCard type="myNfts" key={index} nft={nft} viewNft={(v,t)=>setShowNftCard({card:v,type:t})}/>)}
          </>
        }


            {((myNfts.type=='success' || myNfts.type=='request') && myNfts.data.length < nftInfo.myNftsCount) &&
              <div className="d-flex justify-content-center align-self-center pt-4">
                <button onClick={()=>loadNfts(webInfo.data.nft,currentTab)} className="load-more-btn px-5 align-items-center" disabled={myNfts.type=='request'}> {myNfts.type=='request' ? "Loading..." : "Load More"}</button>
              </div>
            }
          </div>
        </Tab>
      </Tabs>
      </div>
    </>
  )
}


const mapStateToProps = (state) => ({
  user: state.user,
  webInfo:state.webInfo,
  nftInfo: state.nftInfo,
  myNfts: state.myNfts,
  allNfts: state.allNfts,
})

const mapDispatchToProps = {
  loadMyNftsData,loadAllNftsData,userLogin,updateMintValue
}

export default connect(mapStateToProps, mapDispatchToProps)(Gallery)