import React,{useState} from 'react'
import Modalcard from './modal'; 
import {Modal,Button} from 'react-bootstrap';
import TraitDesc from '../trait-desc';


function NftCard(props) {
  const {nft,viewNft,type} = props;

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const trait = [{
    type: 'Trait Type',
    name: 'trait name'
  },{
    type: 'Trait Type',
    name: 'trait name'
  },{
    type: 'Trait Type',
    name: 'trait name'
  },{
    type: 'Trait Type',
    name: 'trait name'
  }]

  return (
    <>
      <div className="col-md-3">
        <div className="card nft-card mb-3" onClick={()=>{viewNft(nft,type)}}>
          <div className="d-flex justify-content-center">
            <img src={nft.image} className="card-img-top img-fluid mx-3 mt-3" alt={nft.name}/>
          </div>
          <div className="card-body">
            <h5 className="card-title text-center text-white">{nft.name}</h5>
          </div>
        </div>
      </div>
 
    </>
  )
}

export default NftCard